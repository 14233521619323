import Actions from "./actions";
import Connect from "./connect";
import MenuMovilApp from "./MenuMovilApp";

const HeaderBotones = ({ connect, arb }) => {
  //Header Buttons section
  return (
		<div className="HeaderBotones">
			<Connect connect={connect} arb={arb} />
			<Actions />
			<MenuMovilApp connect={connect} arb={arb} />
		</div>
  );
};

export default HeaderBotones;
