/* global BigInt */
import { useCallback, useContext, useEffect, useState } from "react";
import { WalletContext } from "./contents";
import { ethers } from "ethers";
import Modal from "./Modal";

import Vault_ABI from "../ABI/GrowiHFVault.json";
import Token_ABI from "../ABI/GrowiHF.json";
import PaymentToken_ABI from "../ABI/USDC.json";
import Addresses from "../addr/addresses.json";

const Cartera = ({ connect, arb, input, qtyOp, recalcBalanceApproval }) => {
	//Wallet buttons logic
	const {
		connected,
		deposit,
		loading,
		account,
		multiButtonText,
		provider,
		setProvider,
		arbitrum,
		installed,
	} = useContext(WalletContext);

	const [modalId, setModalId] = useState(null);
	const [gwhf_vault, set_gwhf_vault] = useState(null);
	const [gwhf_token, set_gwhf_token] = useState(null);
	const [payment_token, set_payment_token] = useState(null);

	useEffect(() => {
		if (provider !== undefined) {
			set_gwhf_vault(
				new ethers.Contract(
					ethers.utils.getAddress(Addresses["gwhf_vault_addr"]),
					Vault_ABI,
					provider
				)
			);

			set_gwhf_token(
				new ethers.Contract(
					ethers.utils.getAddress(Addresses["gwhf_token_addr"]),
					Token_ABI,
					provider
				)
			);

			set_payment_token(
				new ethers.Contract(
					ethers.utils.getAddress(Addresses["payment_token_addr"]),
					PaymentToken_ABI,
					provider
				)
			);
		}
	}, [provider]);

	const approve_usd = async (qty) => {
		try {
			setModalId("DepositApprovalPending");
			let tx = await payment_token.approve(gwhf_vault.address, qty);
			let receipt = await tx.wait(5);
			setModalId("DepositApprovalFinished");
			recalcBalanceApproval();
		} catch (error) {
			if (error.code !== "ACTION_REJECTED") throw error;
			setModalId(null);
			recalcBalanceApproval();
		}
	};

	const approve_gwhf = async (qty) => {
		try {
			setModalId("WithdrawApprovalPending");
			let tx = await gwhf_token.approve(gwhf_vault.address, qty);
			let receipt = await tx.wait(5);
			setModalId("WithdrawApprovalFinished");
			recalcBalanceApproval();
		} catch (error) {
			if (error.code !== "ACTION_REJECTED") throw error;
			setModalId(null);
			recalcBalanceApproval();
		}
	};

	const deposit_func = async (qty) => {
		try {
			setModalId("DepositPending");
			let tx = await gwhf_vault.deposit(qty);
			let receipt = await tx.wait(10);
			setModalId("DepositFinished");
			recalcBalanceApproval();
		} catch (error) {
			if (error.code !== "ACTION_REJECTED") throw error;
			setModalId(null);
			recalcBalanceApproval();
		}
	};

	const withdraw_func = async (qty) => {
		try {
			setModalId("WithdrawPending");
			let tx = await gwhf_vault.withdraw(qty);
			let receipt = await tx.wait(10);
			setModalId("WithdrawFinished");
			recalcBalanceApproval();
		} catch (error) {
			if (error.code !== "ACTION_REJECTED") throw error;
			setModalId(null);
			recalcBalanceApproval();
		}
	};

	const addToken = async () => {
		const tokenDetails = {
			type: "ERC20",
			options: {
				address: Addresses.gwhf_token_addr,
				symbol: "GWHF",
				decimals: 18,
				image: "https://app.hf.growi.fi/Imagenes/gwhf_token_logo.png",
			},
		};

		try {
			await window.ethereum.request({
				method: "wallet_watchAsset",
				params: tokenDetails,
			});
		} catch (error) {
			console.error("User rejected request");
		}
	};

	useEffect(() => {
		if (window.ethereum && window.ethereum.isMetaMask) {
			let aux = new ethers.providers.Web3Provider(
				window.ethereum
			).getSigner();
			setProvider(aux);
		}
	}, [account]);

	//Bottom button logic and text displayed
	const MultiButton = useCallback(() => {
		if (installed) {
			if (!connected) {
				connect();
			} else if (!arbitrum) {
				arb();
			} else if (deposit) {
				if (multiButtonText === "Approve USDC") approve_usd(qtyOp);
				if (multiButtonText === "Deposit") deposit_func(qtyOp);
			} else if (!deposit) {
				if (multiButtonText === "Approve GWHF") approve_gwhf(qtyOp);
				if (multiButtonText === "Deposit") withdraw_func(qtyOp);
			}
		} else {
			window.open("https://metamask.io/download/");
		}
	}, [connected, arbitrum, deposit, installed, multiButtonText, qtyOp]);

	return (
		<div className="Cartera">
			<button
				className="cartera"
				onClick={() => {
					MultiButton();
				}}
				disabled={loading}
			>
				{multiButtonText}
			</button>

			{modalId !== null && (
				<Modal
					modalId={modalId}
					setModalId={setModalId}
					addToken={addToken}
					input={input}
				/>
			)}
		</div>
	);
};

export default Cartera;
