import BackgroundApp from "./backgroundapp";

const Block = ({connect, arb}) => {

    return (
        <div className="Block">
            <BackgroundApp connect={connect} arb={arb} />
        </div>
    );
}

export default Block;