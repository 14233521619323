import Block from "./block";
import ScreenHeader from "./screenheader";

const Screen = ({ connect, arb }) => {
  return (
    <div className="Screen">
      <ScreenHeader />
      <Block connect={connect} arb={arb} />
    </div>
  );
};

export default Screen;
