import './App.css';
import React from 'react';
import Contents from "./Components/contents";

export default function WebApp() {
    return (
        <div className='App'>
        <Contents/>
        </div>
    )
}